var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ReportLayout", {
    attrs: {
      radioName: _vm.radioName,
      radioLogo: _vm.radioLogo,
      reportName: "Relatório de MobAd",
      sublabel: _vm.mobAdName,
      periodStart: _vm.periodStart,
      periodEnd: _vm.periodEnd,
      numberOfPages: 2,
    },
    scopedSlots: _vm._u([
      {
        key: "page-1",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "md-layout-item md-size-100",
                staticStyle: { margin: "0 auto" },
              },
              [
                _c("ReportCard", {
                  attrs: {
                    title: "Estatísticas gerais",
                    labels: ["Visualizações", "Cliques", "*CTR"],
                    keys: ["totalViews", "totalClicks", "totalCTR"],
                    cardData: [
                      {
                        totalViews: _vm.$options.filters.formatNumber(
                          _vm.data.totalViews
                        ),
                        totalClicks: _vm.$options.filters.formatNumber(
                          _vm.data.totalClicks
                        ),
                        totalCTR: `${_vm.data.totalCTR}%`,
                      },
                    ],
                    dataDisplay: "big-columns",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "report-subtitle-footer",
                      fn: function () {
                        return [
                          _c("strong", [_vm._v("*CTR: ")]),
                          _c("i", [_vm._v("Click-through rate")]),
                          _vm._v(
                            ", traduzindo, taxa de cliques. Divisão da quantidade de cliques pela de visualizações. "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "md-layout-item md-size-100",
                staticStyle: { margin: "0 auto" },
              },
              [
                _c("ReportCard", {
                  attrs: {
                    title: "Estatísticas por plataforma",
                    labels: ["Plataforma", "Visualizações", "Cliques", "CTR"],
                    keys: ["os", "views", "clicks", "ctr"],
                    cardData: _vm.data.statisticsPerPlatform,
                    dataDisplay: "short-table",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "page-2",
        fn: function () {
          return [
            _c("ReportCard", {
              attrs: {
                title: "Estatísticas por banner",
                labels: ["Nome", "Área", "Visualizações", "Cliques", "CTR"],
                keys: ["name", "zone", "views", "clicks", "ctr"],
                cardData: _vm.data.statisticsPerBanner,
                dataDisplay: "long-table",
              },
              scopedSlots: _vm._u([
                {
                  key: "report-subtitle-header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "p",
                          {
                            staticClass: "md-layout-item md-size-100",
                            staticStyle: {
                              padding: "0",
                              margin: "0",
                              "text-align": "left",
                            },
                          },
                          [_vm._v(" Plataformas analisadas: ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "md-layout-item md-size-100",
                            staticStyle: {
                              padding: "0",
                              margin: "0",
                              "text-align": "left",
                              "margin-bottom": "14px",
                            },
                          },
                          [
                            _vm._l(
                              _vm.data.platformsSelected,
                              function (platform) {
                                return [
                                  _c("p", { staticClass: "platform-label" }, [
                                    _c("img", {
                                      attrs: { src: platform.icon },
                                    }),
                                    _c("span", [_vm._v(_vm._s(platform.name))]),
                                  ]),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }