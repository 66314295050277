var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { class: `report-card-template ${_vm.dataDisplay}` },
    [
      _c("md-card-header", [
        _c("h3", { staticClass: "main-title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("md-card-content", [
        _c(
          "div",
          { staticClass: "md-layout md-alignment-center-space-around" },
          [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-100" },
              [_vm._t("report-subtitle-header")],
              2
            ),
            _vm.cardData.length !== 0
              ? [
                  _c(
                    "div",
                    { staticClass: "table-row md-layout-item md-size-100" },
                    [
                      _c(
                        "div",
                        { staticClass: "md-layout md-alignment-center-center" },
                        [
                          _vm._l(_vm.labels, function (label) {
                            return [
                              _c(
                                "div",
                                {
                                  class: `md-layout-item md-size-${_vm.calculateColumnSizeForLabels()}`,
                                },
                                [
                                  _c("h5", { staticClass: "secondary-title" }, [
                                    _vm._v(_vm._s(label)),
                                  ]),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._l(_vm.cardData, function (element) {
                    return _c(
                      "div",
                      { staticClass: "table-row md-layout-item md-size-100" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "md-layout md-alignment-center-center",
                          },
                          [
                            _vm._l(_vm.keys, function (key) {
                              return [
                                _c(
                                  "p",
                                  {
                                    class: `md-layout-item md-size-${_vm.calculateColumnSizeForLabels()} text-content`,
                                  },
                                  [_vm._v(" " + _vm._s(element[key]) + " ")]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  _vm._l(_vm.extraRows, function (extraRow, index) {
                    return _vm.extraRows
                      ? _c(
                          "div",
                          {
                            ref: `extra-row-${index}`,
                            refInFor: true,
                            staticClass: "table-row md-layout-item md-size-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout md-alignment-center-center",
                              },
                              [
                                _vm._l(Object.keys(extraRow), function (key) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        class: `md-layout-item md-size-${_vm.calculateColumnSizeForLabels()} text-content extra-row`,
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(extraRow[key]) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                ]
              : [
                  _c("div", { staticClass: "md-layout-item md-size-100" }, [
                    _c("h5", { staticClass: "secondary-title" }, [
                      _vm._v("Sem informações para serem exibidas"),
                    ]),
                  ]),
                ],
          ],
          2
        ),
      ]),
      _c("md-card-footer", [
        _c(
          "h5",
          { staticClass: "report-subtitle" },
          [_vm._t("report-subtitle-footer")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }