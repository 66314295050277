var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "hide-on-print",
          attrs: { visible: _vm.showDialog, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
              },
              [_c("Spinner")],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                staticClass: "md-layout",
              },
              [
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-85 principal" },
                  [
                    _c(
                      "h2",
                      {
                        staticStyle: {
                          "text-align": "left",
                          margin: "0",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v(" " + _vm._s(this.selectedCampaing.name) + " ")]
                    ),
                    _c(
                      "h5",
                      {
                        staticStyle: {
                          "margin-top": "12px",
                          "margin-left": "12px",
                        },
                      },
                      [
                        _c("em", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedCampaing.start_date.toLocaleDateString(
                                  "pt-BR"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" até "),
                        _c("em", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedCampaing.end_date.toLocaleDateString(
                                  "pt-BR"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.averageAdsStatistics.length > 0,
                        expression: "this.averageAdsStatistics.length > 0",
                      },
                    ],
                    staticClass:
                      "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-60 md-xlarge-size-60 principal",
                    staticStyle: { margin: "0 auto" },
                  },
                  [
                    _c(
                      "md-card",
                      { staticStyle: { height: "85%" } },
                      [
                        _c("md-card-header", [
                          _c(
                            "h3",
                            {
                              staticClass: "title-report-campaign",
                              staticStyle: { "text-align": "left" },
                            },
                            [_vm._v(" Estatísticas gerais ")]
                          ),
                        ]),
                        _c("md-card-content", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-evenly",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("h4", [_vm._v("Visualizações")]),
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatNumber")(this.totalViews)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("h4", [_vm._v("Cliques")]),
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatNumber")(this.totalClicks)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("h4", [_vm._v("*CTR")]),
                                  _c("h3", [
                                    _vm._v(_vm._s(this.totalCTR) + "%"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("md-card-footer", [
                          _c(
                            "h5",
                            {
                              staticStyle: {
                                "font-size": "15px",
                                "text-align": "left",
                                padding: "1.5rem",
                                margin: "0",
                              },
                            },
                            [
                              _c("strong", [_vm._v("*CTR: ")]),
                              _c("i", [_vm._v("Click-through rate")]),
                              _vm._v(
                                ", traduzindo, taxa de cliques. Divisão da quantidade de cliques pela de visualizações. "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.averageAdsStatistics.length > 0,
                        expression: "this.averageAdsStatistics.length > 0",
                      },
                    ],
                    staticClass:
                      "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-40 md-xlarge-size-40 principal",
                    staticStyle: { margin: "0 auto" },
                  },
                  [
                    _c(
                      "md-card",
                      { staticStyle: { height: "85%" } },
                      [
                        _c("md-card-header", [
                          _c(
                            "h3",
                            {
                              staticClass: "title-report-campaign",
                              staticStyle: { "text-align": "left" },
                            },
                            [_vm._v(" Estatísticas por plataforma ")]
                          ),
                        ]),
                        _c(
                          "md-card-content",
                          {
                            staticStyle: {
                              "padding-left": "0",
                              "padding-right": "0",
                            },
                          },
                          [
                            _c("table", { staticClass: "table-mobads" }, [
                              _c(
                                "thead",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c("th"),
                                  _c("th", [_vm._v("Visualizações")]),
                                  _c("th", [_vm._v("Cliques")]),
                                  _c("th", [_vm._v("CTR")]),
                                ]
                              ),
                              _c(
                                "tbody",
                                _vm._l(
                                  this.statisticsPerPlatform,
                                  function (item) {
                                    return _c(
                                      "tr",
                                      { key: item, staticClass: "tr_tables" },
                                      [
                                        _c("td", { staticClass: "td_tables" }, [
                                          _vm._v(_vm._s(item.os)),
                                        ]),
                                        _c("td", { staticClass: "td_tables" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatNumber")(item.views)
                                            )
                                          ),
                                        ]),
                                        _c("td", { staticClass: "td_tables" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.clicks
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("td", { staticClass: "td_tables" }, [
                                          _vm._v(_vm._s(item.ctr)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.averageAdsStatistics.length > 0,
                        expression: "this.averageAdsStatistics.length > 0",
                      },
                    ],
                    staticClass:
                      "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 principal",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c("h3", { staticClass: "title-report-campaign" }, [
                              _vm._v("Estatísticas por banner"),
                            ]),
                          ]
                        ),
                        _c(
                          "md-card-content",
                          {
                            staticStyle: {
                              "padding-left": "0",
                              "padding-right": "0",
                            },
                          },
                          [
                            _c("div", { staticClass: "md-layout" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "md-layout-item md-size-100",
                                  staticStyle: { margin: "auto" },
                                },
                                [
                                  _c(
                                    "form",
                                    { attrs: { id: "platform-filter" } },
                                    [
                                      _c(
                                        "fieldset",
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "title-report-campaign subtitle-for-platform-filter",
                                              staticStyle: {
                                                margin: "0 !important",
                                                "margin-bottom":
                                                  "16px !important",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Filtrar por plataforma: "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              attrs: { min: 1 },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.sendMixPanelEvent(
                                                    `mobad_report_platform_filter_used`
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.platformsSelectedByFilter,
                                                callback: function ($$v) {
                                                  _vm.platformsSelectedByFilter =
                                                    $$v
                                                },
                                                expression:
                                                  "platformsSelectedByFilter",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox",
                                                { attrs: { label: 0 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label-checkbox",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "icon-checkbox",
                                                        attrs: {
                                                          src: require("../../../../public/img/icons/android-icon.svg"),
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v("Android"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-checkbox",
                                                { attrs: { label: 1 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label-checkbox",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "icon-checkbox",
                                                        attrs: {
                                                          src: require("../../../../public/img/icons/apple-icon.svg"),
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v("iOS"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-checkbox",
                                                { attrs: { label: 2 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label-checkbox",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "icon-checkbox",
                                                        attrs: {
                                                          src: require("../../../../public/img/icons/web-icon.svg"),
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v("Web"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-checkbox",
                                                { attrs: { label: 3 } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label-checkbox",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "icon-checkbox",
                                                        attrs: {
                                                          src: require("../../../../public/img/icons/question-mark.svg"),
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v("Não definido"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            this.bannersFilteredPerPlatform.length !== 0
                              ? [
                                  _c("table", { staticClass: "table-mobads" }, [
                                    _c(
                                      "thead",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _c("th", [_vm._v("Banner")]),
                                        _c("th", [
                                          _vm._v("Área do dispositivo"),
                                        ]),
                                        _c("th", [_vm._v("Visualizações")]),
                                        _c("th", [_vm._v("Cliques")]),
                                        _c("th", [_vm._v("CTR")]),
                                      ]
                                    ),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        this.bannersFilteredPerPlatform,
                                        function (item, index) {
                                          return _c(
                                            "tr",
                                            {
                                              key: index,
                                              staticClass: "tr_tables",
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "td_tables" },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "td_tables" },
                                                [_vm._v(_vm._s(item.zone))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "td_tables" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("formatNumber")(
                                                        item.views
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "td_tables" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("formatNumber")(
                                                          item.clicks
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "td_tables" },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item.ctr) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "md-layout-item md-alignment-center-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-size-100",
                                          staticStyle: { padding: "0" },
                                        },
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticStyle: {
                                                "text-align": "left",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Sem informações pra serem exibidas."
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide",
                  },
                  [
                    _c(
                      "md-card",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.msgError,
                            expression: "msgError",
                          },
                        ],
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c("md-card-content", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Sem informações pra serem exibidas."),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-100 principal" },
                  [
                    _c(
                      "div",
                      { staticClass: "md-layout md-alignment-center-right" },
                      [
                        this.averageAdsStatistics.length > 0
                          ? _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-layout-item md-size-15 md-info",
                                on: {
                                  click: function ($event) {
                                    return _vm.printReport()
                                  },
                                },
                              },
                              [_vm._v(" Imprimir ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
      _c("TemplateReportMobAd", {
        attrs: {
          id: "report-campaign",
          radioName: _vm.nameRadioReport,
          radioLogo: this.radio.logo,
          mobAdName: this.selectedCampaing.name,
          periodStart:
            this.selectedCampaing.start_date.toLocaleDateString("pt-BR"),
          periodEnd: this.selectedCampaing.end_date.toLocaleDateString("pt-BR"),
          data: {
            totalClicks: this.totalClicks,
            totalViews: this.totalViews,
            totalCTR: this.totalCTR,
            statisticsPerPlatform: this.statisticsPerPlatform,
            statisticsPerZone: this.statisticsPerZone,
            platformsSelected: this.mapPlatformsToPrintReport(),
            statisticsPerBanner: this.bannersFilteredPerPlatform,
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }